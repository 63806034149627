{
    "fr": {
        "translation": {
            "begin_msg": {
                "1": "Pour commencer à utiliser",
                "2": "veuillez renseigner l'URL d'un",
                "3": "manifeste",
                "4": "ou d'une",
                "5": "image IIIF",
                "6": "d'une",
                "7": "image statique",
                "8": "au format jpg ou png, ou encore d'un",
                "9": "projet Adno"
            },
            "create_project": " Créer mon projet",
            "import": {
                "import_project": "Importer un projet",
                "import_now": "directement",
                "validate": "Valider l'importation",
                "cancel": "Annuler l'importation",
                "adno_project": "Projet Adno détecté, voulez-vous l'importer ?",
                "import_success": "Projet importé avec succès"
            },
            "projects": {
                "all": "Vos projets existants",
                "nothing": "Aucun projet disponible pour le moment"
            },
            "project": {
                "created_on": "Créé le ",
                "last_update": "Dernière mise à jour le ",
                "no_desc": "Aucune description disponible pour ce projet",
                "no_creator": "Pas d'auteur indiqué",
                "no_editor": " — Pas d'éditeur indiqué",
                "preview": "Prévisualiser",
                "edit": "Editer",
                "delete": "Supprimer",
                "duplicate": "Dupliquer",
                "download": "Télécharger",
                "new": "Créer mon projet",
                "new_tooltip": "Créer un nouveau projet",
                "title": "Titre",
                "description": "Description",
                "author": "Auteur",
                "editor": "Editeur",
                "manifest_url": "URL",
                "create": "Créer mon projet",
                "back": "Retour",
                "add_desc": "Description de votre projet (optionnel)",
                "add_title": "Donnez un titre à votre projet",
                "copy": "copie",
                "cancel": "Annuler",
                "choose": "Sélectionnez une image",
                "choose_desc": "Choisissez l'image que vous souhaitez utiliser pour votre projet Adno",
                "choose_canva": "Utiliser cette image pour mon projet",
                "back_home": "Annuler et revenir à l'accueil",
                "metadatas": {
                    "identifier": "Identifiant",
                    "source": "Source",
                    "creation_date": "Date de création",
                    "last_update": "Dernière modification",
                    "format": "Format",
                    "rights": "Droits attribués",
                    "allocation_rights": "Attribution des droits"
                },
                "metadatas_plh": {
                    "title": "Donnez un titre à ce projet",
                    "description": "Ajoutez une description à votre projet",
                    "author": "Renseignez ici l'auteur",
                    "editor": "Renseignez ici l'editeur",
                    "allocation_rights": "Renseignez ici les droits de l'oeuvre"
                },
                "settings": {
                    "delay": "Délai entre 2 annotations (en secondes)",
                    "outline_width": "Épaisseur des annotations",
                    "outline_color": "Couleur des contours des annotations",
                    "outline_focus": "Couleur du contour de l'annotation courante",
                    "navigator": "Navigateur d'ensemble",
                    "fullscreen": "Afficher barre outils en mode plein écran",
                    "annos_nav": "Afficher la barre de navigation pour les annotations",
                    "begin_first_anno": "Toujours commencer la lecture automatique à la première annotation",
                    "enable_rota": "Activer la rotation de l'image",
                    "toolsbar": "Afficher la barre d'outils",
                    "save": "Enregistrer",
                    "tags": "Seules les annotations incluant le ou l'un des mots clés suivants seront affichées",
                    "show_outlines": "Afficher les contours",
                    "show_eyes": "Afficher une icone à l'intérieur des formes",
                    "no_sound": "Les annotations audio ne seront pas lues",
                    "spatialization": "Les annotations audio seront lues avec un effet de spatialisation",
                    "no_spatialization": "Les annotations audio seront lues individuellement",
                    "annotation_sound": "Choisissez un mode pour les annotations audio",
                    "visualization": "Visualisation",
                    "navigation": "Navigation",
                    "annotation": "Annotation",
                    "anno_bounds": "Voir les annotations ?"
                }
            },
            "files": {
                "one_selected": "1 Fichier selectionné"
            },
            "buttons": {
                "cancel": "Annuler"
            },
            "editor": {
                "approve_changes": "Valider les modifications",
                "md_add_tag": "Ajouter un mot clé",
                "audio_track": "Piste audio (URL)",
                "audio_creator": "Créateur de la piste audio (Crédit)",
                "md_save": "Enregistrer",
                "md_delete": "Supprimer",
                "md_delete_confirm": "Confirmer la suppression",
                "fullpage": "Mettre en plein écran",
                "home": "Aller à l'accueil",
                "zoom_in": "Zoomer",
                "zoom_out": "Dézoomer",
                "next_page": "Page suivante",
                "previous_page": "Page précédente",
                "rotate_left": "Tourner vers la gauche",
                "rotate_right": "Tourner vers la droite",
                "flip": "Retourner horizontalemenent",
                "placeholder": "Ajoutez votre texte ici"
            },
            "visualizer": {
                "expand": "Mettre en plein écran",
                "pause": "Mettre en pause",
                "play": "Lancer",
                "reset_zoom": "Dézoomer",
                "toggle_annotations": "Afficher/masquer les annotations",
                "previous_annotation": "Annotation précédente",
                "next_annotation": "Annotation suivante",
                "rotation": "Tourner de 90 degrés",
                "help": "Aide",
                "help_title": "Raccourcis clavier",
                "help_key": "— touche ",
                "help_key_plural": "— touches ",
                "help_or": " ou ",
                "help_and": " et ",
                "help_key_p": " pour démarrer automatiquement,",
                "help_key_e": " pour activer le mode plein écran,",
                "help_key_escape": " pour quitter le mode plein écran,",
                "help_key_s": " pour afficher les contours des annotations,",
                "help_key_t": " pour afficher les icones de navigation et de visualisation,",
                "help_key_arrows": " flèches (gauche et droite) pour changer l'annotation courante.",
                "help_doc": "Pour plus d'informations, consultez la ",
                "help_doc_link": "documentation officielle de Adno",
                "close": "Fermer"
            },
            "modal": {
                "delete_project": "Voulez-vous vraiment supprimer ce projet ?",
                "confirm_delete": "Supprimer mon projet",
                "cancel": "Annuler",
                "projects_list_up_to_date": "La liste des projets a bien été mise à jour !",
                "duplicate_project": "Voulez-vous vraiment dupliquer ce projet ?",
                "confirm_duplication": "Dupliquer mon projet",
                "annotation_moved": "Annotation déplacée avec succès",
                "ask_delete": "Voulez-vous vraiment supprimer cette annotation ?",
                "confirm_del_annotation": "Oui, supprimer cette annotation",
                "del_annotation_confirmation": "L'annotation a bien été supprimée",
                "project_edit_success": "Projet édité avec succés !",
                "settings_updated_success": "Paramètres mis à jour avec succés !",
                "adno_proj_detected": "Projet Adno détecté, voulez-vous l'importer ?",
                "old_version": "Un ou plusieurs projets ont été fait avec une version obsolète d’Adno",
                "update_old_version": "Mettre à jour vers la dernière version",
                "version_updated": "Félicitations, vos projets Adno sont à jour !"
            },
            "annotation": {
                "no_content": "aucun contenu",
                "read_more": "Lire plus",
                "edit": "Editer",
                "target": "Mettre en évidence",
                "switch_down": "Descendre l'annotation",
                "switch_up": "Remonter l'annotation",
                "delete": "Supprimer l'annotation",
                "tags_list": "Filter les annotations comportant les mots clés",
                "empty_tags_list": "Les mots clés apparaitront ici"
            },
            "navbar": {
                "edit_mode": "Mode édition",
                "view_mode": "Mode lecture",
                "back_home": "Retourner à l'accueil",
                "download_project": "Télécharger le projet",
                "edit_project": "Edit the project",
                "show_metadatas": "Voir les métadonnées",
                "share_project": "Sauvegarde et partage du projet",
                "share_project_desc1": "Téléchargez d’abord le fichier du projet. Pour le partager, vous pouvez, soit le transmettre par mail, soit le déposer en ligne — sur un site web, un serveur de fichiers ou sur le système de fichier interplanétaire IPFS —, puis diffuser son URL ou son CID IPFS.",
                "share_project_desc2": "Dès lors que votre projet Adno est en ligne, il est possible de le rendre visible dans une page web en insérant une balise iframe.",
                "share_project_desc3": "Pour plus d'informations, rendez-vous sur la",
                "share_project_desc4": "documentation officielle de Adno."
            },
            "new_project": {
                "add_infos_1": "Renseignez votre projet",
                "add_infos_2": ""
            },
            "errors": {
                "error_found": "Erreur détectée",
                "unable_reading_file": "Impossible de lire ce fichier, veuillez renseigner un fichier ayant un des formats suivant : png, jpg, json",
                "unable_access_file": "Impossible d'accéder à ce fichier !",
                "manifest_picture_not_found": "Erreur - Le manifeste ou l'image que vous avez entré est introuvable",
                "wrong_url": "L'URL renseignée n'est pas valide ! Indiquez un lien vers un manifeste ou une image IIIF, une image JPG, PNG, ou encore un fichier d'un projet Adno",
                "no_url": "Aucune URL n'a été fournie",
                "unknown_characters": "Impossible de lire les annotations (Caractères interdits)",
                "no_title": "Veuillez donner un titre à votre projet",
                "unable_reading_manifest": "Impossible de lire le manifeste",
                "no_iiif": "Projet non IIIF détecté, veuillez renseigner un projet IIIF",
                "wrong_file": "Ce fichier n'est pas lisible par ADNO ! Veuillez réessayer avec un autre fichier"
            },
            "footer": "Poitiers. Ce projet a été soutenu par le ministère français de la Culture",
            "tags_infos": "Veuillez appuyer sur la touche 'entrée' pour valider chaque mot clé",
            "link": {
                "url": "Saisissez l'URL vers les ressources IIIF",
                "h1": "Generateur d'URL",
                "preview": "Prévisualisation de l'URL",
                "generate": "Copier URL"
            }
        }
    }
}